@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply m-0 p-0 min-h-screen flex flex-col;
}

.App {
    @apply flex-1;
}

.main-content {
    @apply flex-1 pt-16;
}

.footer {
    @apply bg-gray-800 text-white text-center p-6;
}

header .container {
    @apply px-4;
}

#home p {
    @apply px-4 md:px-0;
}

.card {
    position: relative;
    padding: 1.5rem;
    background: #fff;
    border-radius: 0.75rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff9f1c;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    margin: 0 auto 1rem auto;
    color: #fff;
}

.card h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.card p {
    font-size: 1rem;
    color: #333;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in-up {
    animation: fadeInUp 0.6s ease-in-out forwards;
}

.ribbon {
    position: absolute;
    top: 10px;
    left: -10px;
    background-color: rgba(255, 159, 28, 0.8);
    color: white;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: bold;
    transform: rotate(-15deg);
    z-index: 1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Styles for Scroll to Top button */
.fixed {
    position: fixed;
    z-index: 50;
}

.bottom-4 {
    bottom: 1rem;
}

.right-4 {
    right: 1rem;
}

.bg-yellow-500 {
    background-color: #ff9f1c;
}

.hover\:bg-yellow-600:hover {
    background-color: #cc7f16;
}

.transition {
    transition: all 0.3s ease-in-out;
}

.duration-300 {
    transition-duration: 300ms;
}

.cursor-pointer {
    cursor: pointer;
}

.rounded-full {
    border-radius: 9999px;
}

.p-3 {
    padding: 0.75rem;
}

.shadow-lg {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.text-white {
    color: #fff;
}

/* Typography */
h1, h2, h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

p {
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
    color: #555;
}

.service-item {
    position: relative;
    overflow: hidden;
    padding: 2rem;
    background: #fff;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left; /* Ensures left alignment of text */
}

.service-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
}

.service-item .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff9f1c;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    margin: 0 auto 1.5rem auto;
    color: #fff;
}

.service-item h3 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
}

.service-item p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* Custom bullet points */
.service-item ul {
    list-style-type: none;
    padding: 0;
}

.service-item ul li {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #666;
    margin-bottom: 0.5rem;
}

.service-item ul li .bullet-icon {
    color: #ff9f1c;
    margin-right: 0.5rem;
    font-size: 1.25rem; /* Ensures all bullet icons are the same size */
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 90%;
    z-index: 1000;
    overflow: auto;
    max-height: 90vh;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
}
